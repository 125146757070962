<template>
  <div id="request" class="pb-6">

    <v-app-bar flat inverted-scroll scroll-threshold="200">
      <v-app-bar-title>
      <v-chip small color="primary" class="mr-1">#{{request.id}}</v-chip>
        {{request.subject}}
      </v-app-bar-title>
    </v-app-bar>

    <v-toolbar flat height="auto" extension-height="auto">
      <v-toolbar-title class="">
        <v-btn rounded @click="$router.go(-1)" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-chip small color="secondary" class="mr-1">#{{request.id}}</v-chip>
        {{request.subject}}
      </v-toolbar-title>
      <v-spacer />
      <v-btn text @click="show_detail = !show_detail" :input-value="show_detail"><v-icon small>mdi-unfold-more-horizontal</v-icon>詳細</v-btn>
      <template v-slot:extension>
        <v-sheet v-show="show_detail" class="px-2 py-5 flex-grow-1">
          <v-expand-transition>
            <v-expansion-panels v-show="show_detail" v-model="detailPanels">

              <!-- basic info -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>基本情報</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <h4>公開希望日時</h4>
                      <p>{{ request.release_date }} {{ request.release_time }}</p>
                    </v-col>
                      <v-col cols="12" md="4" v-if="request.point_allocate_date ">
                      <h4>計上日</h4>
                      <p>{{ request.point_allocate_date }}</p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- status history -->
              <v-expansion-panel>
                <v-expansion-panel-header>ステータス履歴</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense v-if="request.status_histories && request.status_histories.length > 0">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>ステータス</th>
                            <th>日時</th>
                            <th>変更者</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in request.status_histories" :key="i">
                            <td>{{ item.status_mst.status }}</td>
                            <td>{{ item.change_at | moment("YYYY/MM/DD HH:mm") }}</td>
                            <td>{{ item.assigned_login.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- service info-->
              <v-expansion-panel>
                <v-expansion-panel-header>サービス</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template>
                    <div id="service-list" v-if="request.plan_mst">
                      <v-data-table dense disable-sort :headers="table_headers" :items-per-page="-1" :items="request.request_points" class="elevation-0" hide-default-footer hide-default-header no-data-text="サービスは登録されていません">
                        <template v-slot:[`item.count`]="{ item }">
                          {{ item.fix_count}}<span class="unit">{{unit}}</span>
                        </template>
                        <template v-slot:[`body.append`]>
          <tr v-if="totalPoint > 0 && request.point_rate !== 1">
            <td class="text-right">小計</td>
            <td colspan="1" class="text-right"><span class="font-weight-bold">{{ totalPoint }}</span><span class="unit">{{unit}}</span></td>
          </tr>
          <tr v-if="request.point_rate && request.point_rate !== 1">
            <td class="text-right">倍率</td>
            <td colspan="1" class="text-right"><span class="font-weight-bold">{{ request.point_rate }}</span><span class="unit">倍</span></td>
          </tr>
          <tr v-if="totalPoint > 0 && request.point_rate">
            <td class="text-right">合計</td>
            <td colspan="1" class="text-right"><span class="text-h6">{{ totalPoint * request.point_rate }}<span class="unit">{{unit}}</span></span></td>
          </tr>
                        </template>
                      </v-data-table>
                  </div>
                </template>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- contract partner -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>依頼関係者
                  <v-dialog max-width="400px" persistent v-model="member_dialog">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small class="edit-member ml-2" v-bind="attrs" v-on="on"><v-icon small>mdi-pencil</v-icon></v-btn>
                    </template>
                    <v-card>
                      <v-card-title>依頼関係者の編集</v-card-title>
                      <v-card-text>
                        <v-select chips v-model="request_member" :items="selectAccount" label="" multiple hide-details no-data-text="アカウントが登録されていません"></v-select>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                          <v-btn text @click="closeMemberDialog()">キャンセル</v-btn>
                          <v-btn class="primary" @click="updateMember()">決定</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  </span></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row no-gutters>
                      <v-col v-for="(item, i) in request_member" :key="i" cols="12" sm="6" md="4" class="py-1">
                        <LoginAvatar :user="getAccountData(item)" :size="32" class="mr-1" />
                        <span v-if="getAccountData(item)">{{ getAccountData(item).name }}</span>
                        <span v-else>削除済みユーザー</span>
                      </v-col>
                    </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- タスク所有者 -->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span>
                    タスク所有者<v-btn icon small class="edit-assigned_login ml-2" @click.stop="editAssignedLogin()" v-if="detailPanels === 4"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="4" class="py-1">
                      <template v-if="request.assigned_login">
                        <LoginAvatar :user="request.assigned_login" :size="32" class="mr-1" />
                        <span>{{ request.assigned_login.name }}</span>
                      </template>
                    </v-col>
                  </v-row>
                  <v-dialog max-width="400px" persistent v-model="crud.assigned_login.edit">
                    <v-card>
                      <v-card-title>タスク所有者の編集</v-card-title>
                      <v-divider />
                      <v-card-text>
                        <v-select v-model="request_assigned_login" :items="selectRequestAssignedLogins" label="" placeholder="タスク所有者の選択" hide-details></v-select>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="cancelEditAssignedLogin()">キャンセル</v-btn>
                        <v-btn class="primary" @click="updateAssignedLogin()">保存</v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
          </v-expand-transition>
        </v-sheet>
      </template>
    </v-toolbar>
    <v-container id="timeline">
      <v-row>
        <v-col>
          <!-- Request -->
          <v-card>
              <v-card-text class="pa-3 pa-md-5">
                <!-- <div class="text-right text-body-2">{{request.create_at | moment(defaultDateFormat) }}</div> -->
                <div class="d-flex flex-column flex-md-row">
                  <div class="text-center mb-4 flex-md-shrink-0 col-md-2">
                    <template v-if="request.submit_login">
                      <LoginAvatar :user="request.submit_login" :size="64" />
                      <div class="text-caption mt-2">
                        {{request.create_at | moment(defaultDateFormat) }}<br>
                        {{request.submit_login.name}}
                      </div>
                    </template>
                  </div>
                  <div class="flex-glow-1 pl-md-3 col-md-10">
                    <div v-show="canEditRequestContent(request)">
                      <span v-text="request.request_content" ref='request-content-span' v-linkified></span>
                      <textarea :value="request.request_content" ref='request-content' @keydown=requestContentResize :style=contentAreaStyle disabled></textarea>
                    </div>
                    <span v-show="!canEditRequestContent(request)" v-text="request.request_content" v-linkified></span>
                  </div>
                </div>
                <v-layout v-show="canEditRequestContent(request)">
                  <v-spacer/>
                  <div ref='request-content-buttons' style="display: none;">
                      <v-btn small text @click="editRequestContentCancel(request)">キャンセル</v-btn>
                      <v-btn small class="primary" @click="saveRequestContent(request)">保存</v-btn>
                  </div>
                  <v-icon small class="ml-2" @click="editRequestContent()">mdi-pencil</v-icon>
                </v-layout>
              </v-card-text>
              <template v-if="request.file_attachments && request.file_attachments.length">
              <v-card-actions class="px-4 py-1">
                <v-chip-group column>
                  <template v-for="file in request.file_attachments">
                    <FileAttachmentChip :key="file.id" :file="file"></FileAttachmentChip>
                  </template>
                </v-chip-group>
                <v-spacer />
              </v-card-actions>
              </template>

              <v-divider />
              <v-card-text class="px-4 py-2">
                <emoji-reaction :request-id="request.id"/>
              </v-card-text>
          </v-card>

          <!-- Timeline -->
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <template v-for="(item, i) in request.timeline">
              <div :key="i">
              <v-timeline-item v-if="item.comment" :right="item.submit_login.is_admin_user" :left="item.submit_login.is_end_user" :class="{end_user: item.submit_login.is_end_user, admin_user: item.submit_login.is_admin_user}">
                <template v-slot:icon>
                  <LoginAvatar :user="item.submit_login" :size="($vuetify.breakpoint.smAndDown ? 32 : 48)" />
                </template>
                <template v-slot:opposite>
                  <span class="d-block text-caption">
                    {{item.create_at | moment(defaultDateFormat) }}
                    <span v-if="item.update_at">[edited]</span>
                  </span>
                  <v-icon small class="mr-1" v-show="item.send_mail_flag" >mdi-email-check</v-icon>
                  <span v-if="item.submit_login.delete_at" class="text-caption">{{ item.submit_login.name }}(削除済み)</span>
                  <span v-else>{{ item.submit_login.name }}</span>
                </template>
                <v-card class="elevation-2">
                  <v-card-text class="pa-3">
                    <div class="text-caption mb-2 d-md-none">
                      <span class="mr-2" >{{item.create_at | moment(defaultDateFormat) }}</span>
                      <v-icon small class="mr-1" v-show="item.send_mail_flag" >mdi-email-check</v-icon>{{ item.submit_login.name }}
                    </div>
                    <div v-show=canEditTimelineComment(item)>
                      <span v-text="item.comment" :ref="'timeLine-comment-span' + item.id" v-linkified></span>
                      <textarea :value="item.comment" :ref="'timeLine-comment-area' + item.id" class="edit-timeline-comment" @keydown=timelineCommentResize :style=contentAreaStyle disabled></textarea>
                      <v-layout>
                        <v-spacer />
                        <div :ref="'timeLine-comment-buttons' + item.id" style="display: none;">
                          <v-btn small text @click="editTimelineCommentCancel(item)">キャンセル</v-btn>
                          <v-btn small class="primary" @click="saveTimelineComment(item)">保存</v-btn>
                        </div>
                        <v-icon small class="ml-2" @click="editTimeLimeComment(item)">mdi-pencil</v-icon>
                      </v-layout>
                    </div>
                    <span v-show=!canEditTimelineComment(item) v-text="item.comment" v-linkified></span>
                  </v-card-text>
                  <v-card-actions v-if="item.file_attachments && item.file_attachments.length" class="px-4 pb-1 pt-0">
                    <v-chip-group column>
                      <template v-for="file in item.file_attachments">
                        <FileAttachmentChip :key="file.id" :file="file"></FileAttachmentChip>
                      </template>
                    </v-chip-group>
                  </v-card-actions>
                  <v-divider />
                  <v-card-text class="px-4 py-2">
                    <emoji-reaction :comment-id="item.id"/>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
              <div v-if="item.status_mst" class="changed-status">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip small v-bind="attrs" v-on="on" :color="item.assigned_login.user_type == 'admin' ? 'primary':'secondary' ">{{ item.status_mst.status }}</v-chip>
                  </template>
                  <span>{{ item.assigned_login.name }}</span>
                </v-tooltip>
              </div>
              <!-- タスク所有者 -->
              <v-timeline-item v-if="showAssignedLoginInTimeline && (i === request.timeline.length - 1)" :right="request.assigned_login.is_admin_user" :left="request.assigned_login.is_end_user" :class="{end_user: request.assigned_login.is_end_user, admin_user: request.assigned_login.is_admin_user}">
                <template v-slot:icon>
                  <LoginAvatar :user="request.assigned_login" :size="($vuetify.breakpoint.smAndDown ? 32 : 48)" />
                </template>
                <template v-slot:opposite>
                  <span class="d-block text-caption">タスク所有者</span>
                  <span>{{ request.assigned_login.name }}</span>
                </template>
              </v-timeline-item>
              </div>
            </template>

            <!-- Action Button -->
            <div class="add text-center">
              <v-menu top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-large fab dark color="primary" elevation="5" v-bind="attrs" v-on="on"><v-icon>mdi-comment-plus</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in ActiveChangeStatus" :key="index" @click="showStatusChangeDialog({state_from: request.current_status, state_to: item, request_id: request.id})">
                    <v-list-item-title><v-icon left>mdi-checkbox-marked-outline</v-icon>{{ item.status }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showStatusChangeDialog({request_id: request.id})">
                    <v-list-item-title><v-icon left>mdi-comment-plus-outline</v-icon>コメント</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>

    <!-- 依頼情報 -->
    <v-navigation-drawer app clipped right width="190" mobile-breakpoint="700" id="sub-nav">
      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="mb-2">公開希望日時
              <v-chip x-small outlined class="px-1 ml-2" v-if="diffDate(request.release_date) > 0">
                <template v-if="diffDate(request.release_date) === 0">本日</template>
                <template v-else>{{ diffDate(request.release_date) }}日後</template>
              </v-chip>
            </v-list-item-subtitle>
            <v-list-item-title class="text-h6">{{ request.release_date | moment("YYYY/M/D(ddd)") }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ request.release_time }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="request.plan_mst && request.plan_mst.contract_model == 'point'">
          <v-list-item-content>
            <v-list-item-subtitle>利用ポイント</v-list-item-subtitle>
            <div>
              <span class="text-h6">{{ requestFinalPoint }}</span><span class="unit">Pt</span>
                <v-chip x-small outlined class="px-1 ml-2" v-if="request.point_rate && request.point_rate != 1">
                <span class="point-rate">
                {{ totalPoint }}<span class="unit">Pt</span> × {{ request.point_rate }}<span class="unit">倍</span></span>
                </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-stepper v-model="status_stepper_step" flat vertical>
              <template v-for="(item, i) in AllStatusWithoutCancel">
                <v-stepper-step :step="item.step" :key="i">
                  {{ item.status }}
                </v-stepper-step>
                <v-stepper-content :step="item.step" :key="`content${i}`"></v-stepper-content>
              </template>
            </v-stepper>

          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <!-- ダイアログ用スロット -->
    <componentSlot :is="componentSlot.component" :params="componentSlot.params" v-on="componentSlot.event" />

  </div>
</template>

<script>
import StatusChange from '@/components/StatusChange.vue'
import axios from '@/plugins/axios'
import moment from 'moment'
import EmojiReaction from '../../components/EmojiReaction.vue'
import FileAttachmentChip from '@/components/FileAttachmentChip.vue'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  name: 'request',
  inheritAttrs: false,
  components: {
    StatusChange,
    EmojiReaction,
    FileAttachmentChip,
    LoginAvatar
  },
  data: function () {
    return {
      show_detail: false,
      member_dialog: false,
      status_stepper_step: null,
      detailPanels: null,
      crud: {
        assigned_login: {
          edit: false,
          restore: ''
        }
      },
      memberRestore: [],
      componentSlot: { // ステータス変更コンポーネント読み込み用
        component: '',
        params: {
          type: '',
          request: null,
          state_from: null,
          state_to: null
        },
        event: {
          close: this.hideStatusChangeDialog,
          reload: this.readDataFromAPI
        }
      },
      table_headers: [
        { text: 'サービス名', value: 'point_mst.service' },
        { text: '消費', value: 'count', align: 'end', cellClass: 'point' }
      ],
      request: {}, // 依頼情報
      request_member: [],
      request_assigned_login: '',
      requester_accounts: [],
      unacceptedStatusId: 1,
      completeStatusId: 7
    }
  },
  watch: {
    resize () {
      this.requestContentResize()
      this.timelineCommentResize()
    }
  },
  created: function () {
    this.request.id = this.$route.params.id
    this.readDataFromAPI()
  },
  mounted: function () {
    const interval1 = setInterval(() => {
      if (this.$refs['request-content']?.value) {
        this.requestContentResize()
        clearInterval(interval1)
      }
    }, 50)
    const interval2 = setInterval(() => {
      if (Array.from(document.getElementsByClassName('edit-timeline-comment')).length !== 0) {
        this.timelineCommentResize()
        clearInterval(interval2)
      }
    }, 50)
  },
  methods: {
    getAccountData: function (id) {
      if (!id || !this.requester_accounts.length) {
        return { name: '', photo: { file_url: '' } }
      }
      return this.requester_accounts.find((v) => v.id === id)
    },
    setDeleteAccountName: function (item) {
      if (item.delete_at != null) {
        item.name = item.name + '(削除済み)'
      }
    },
    getEndUserLogins: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${this.request.end_user.id}`)
        .then(res => {
          this.requester_accounts = res.data.logins
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateMember () {
      // console.info('update logic')
      const self = this
      const formData = new FormData()
      for (const loginId of self.request_member) {
        formData.append('secondary_submit_login[]', loginId)
      }
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request.id}/update_submit_logins`, formData)
        .then(res => {
          self.$store.commit('setFlashSuccess', '依頼関係者が正常に更新されました')
          self.readDataFromAPI()
        })
        .catch(err => {
          this.request_member = this.memberRestore
          self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.member_dialog = false
    },
    closeMemberDialog: function () {
      this.request_member = this.memberRestore
      this.member_dialog = false
    },
    showStatusChangeDialog: function (option) {
      this.componentSlot.component = 'StatusChange'
      this.componentSlot.params = option
      this.componentSlot.params.request = this.request
      return false
    },
    hideStatusChangeDialog: function () {
      this.componentSlot.component = ''
      this.componentSlot.props = {}
      this.status_stepper_step = this.request.current_status.step
    },
    editAssignedLogin () {
      this.crud.assigned_login.restore = this.request_assigned_login
      this.crud.assigned_login.edit = true
    },
    updateAssignedLogin () {
      const self = this
      const formData = new FormData()
      formData.append('assigned_login_id', self.request_assigned_login)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${self.request.id}/update_assigned_login`, formData)
        .then(res => {
          self.$store.commit('setFlashSuccess', 'タスク所有者を更新しました')
          self.readDataFromAPI()
        })
        .catch(err => {
          this.request_assigned_login = this.crud.assigned_login.restore
          self.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.crud.assigned_login.edit = false
    },
    cancelEditAssignedLogin () {
      this.request_assigned_login = this.crud.assigned_login.restore
      this.crud.assigned_login.edit = false
    },
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${this.request.id}`)
        .then(res => {
          this.request = res.data
          // console.log(this.request)
          this.setDeleteAccountName(this.request.submit_login)
          this.status_stepper_step = this.request.current_status.step
          this.request_member = []
          this.request_assigned_login = (this.request.assigned_login ? this.request.assigned_login?.id : '')
          this.request.involved_logins.end_user.forEach(item => {
            if (item.end_user && item.id !== this.request.submit_login.id) {
              if (!this.request_member.includes(item.id)) {
                this.request_member.push(item.id)
              }
            }
          })
          this.memberRestore = this.request_member
          this.getEndUserLogins()
          const interval = setInterval(() => {
            if (Array.from(document.getElementsByClassName('edit-timeline-comment')).length !== 0) {
              this.timelineCommentResize()
              clearInterval(interval)
            }
          }, 50)
          document.title = document.title.replace('依頼詳細', '【#' + this.request.id + '】' + this.request.subject)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.scrollTimeline()
        })
    },
    diffDate: function (expireDate, today) {
      return (moment(expireDate).diff(today, 'days'))
    },
    scrollTimeline () {
      this.$nextTick(() => {
        var element = document.documentElement
        var bottom = element.scrollHeight - element.clientHeight
        // console.log(element.scrollHeight)
        window.scrollTo({ top: bottom, left: 0, behavior: 'smooth' })
      })
    },
    isLoginUserContent (item) {
      if (item.submit_login_id === this.$store.state.login_user.id) {
        return true
      }
      return false
    },
    // 依頼内容編集メソッド
    canEditRequestContent (request) {
      return this.isLoginUserContent(request) && request.current_status_id === this.unacceptedStatusId
    },
    requestContentResize () {
      const contentAreaRef = 'request-content'
      this.$refs[contentAreaRef].style.height = 'auto'
      this.$nextTick(() => {
        this.$refs[contentAreaRef].style.height = this.$refs[contentAreaRef].scrollHeight + 'px'
      })
    },
    editRequestContent () {
      const contentAreaRef = 'request-content'
      const contentButtonsRef = 'request-content-buttons'
      const contentSpanRef = 'request-content-span'
      this.$refs[contentButtonsRef].style.display = 'block'
      this.$refs[contentAreaRef].style.display = 'block'
      this.$refs[contentSpanRef].style.display = 'none'
      this.requestContentResize()
      this.$refs[contentAreaRef].disabled = false
      this.$refs[contentAreaRef].focus()
    },
    changeDisableRequestContent () {
      const contentAreaRef = 'request-content'
      const contentButtonsRef = 'request-content-buttons'
      const contentSpanRef = 'request-content-span'
      this.$refs[contentAreaRef].disabled = true
      this.$refs[contentButtonsRef].style.display = 'none'
      this.$refs[contentAreaRef].style.display = 'none'
      this.$refs[contentSpanRef].style.display = 'block'
    },
    saveRequestContent (request) {
      const contentAreaRef = 'request-content'
      if (request.request_content === this.$refs[contentAreaRef].value) {
        this.changeDisableRequestContent()
        return
      }
      if (this.$refs[contentAreaRef].value.length === 0) {
        this.$refs[contentAreaRef].value = request.request_content
        this.requestContentResize()
        this.changeDisableRequestContent(request)
        return
      }
      const formData = new FormData()
      formData.append('request_content', this.$refs[contentAreaRef].value)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request/${request.id}/content_update`, formData)
        .then(res => {
          console.log(res.data)
          this.readDataFromAPI()
          this.$store.commit('setFlashSuccess', '依頼内容を編集しました。')
        })
        .catch(err => {
          this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.changeDisableRequestContent()
    },
    editRequestContentCancel (request) {
      const contentAreaRef = 'request-content'
      this.$refs[contentAreaRef].value = request.request_content
      this.requestContentResize()
      this.changeDisableRequestContent()
    },
    // タイムラインコメント編集メソッド
    canEditTimelineComment (item) {
      if (this.isLoginUserContent(item) && this.request.current_status_id !== this.completeStatusId) {
        return true
      }
      return false
    },
    timelineCommentResize () {
      const htmlEditComments = document.getElementsByClassName('edit-timeline-comment')
      const editComments = Array.from(htmlEditComments)
      editComments.map(function (editComment) {
        editComment.style.height = 'auto'
      })
      editComments.map(function (editComment) {
        editComment.style.height = editComment.scrollHeight + 'px'
      })
    },
    editTimeLimeComment (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      const contentButtonsRef = 'timeLine-comment-buttons' + item.id
      const commentSpanRef = 'timeLine-comment-span' + item.id
      this.$refs[contentButtonsRef][0].style.display = 'block'
      this.$refs[commentAreaRef][0].style.display = 'block'
      this.$refs[commentSpanRef][0].style.display = 'none'
      this.timelineCommentResize()
      this.$refs[commentAreaRef][0].disabled = false
      this.$refs[commentAreaRef][0].focus()
    },
    changeDisableTimeLineComment (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      const contentButtonsRef = 'timeLine-comment-buttons' + item.id
      const commentSpanRef = 'timeLine-comment-span' + item.id
      this.$refs[commentAreaRef][0].disabled = true
      this.$refs[contentButtonsRef][0].style.display = 'none'
      this.$refs[commentAreaRef][0].style.display = 'none'
      this.$refs[commentSpanRef][0].style.display = 'block'
    },
    saveTimelineComment (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      if (item.comment === this.$refs[commentAreaRef][0].value) {
        this.changeDisableTimeLineComment(item)
        return
      }
      if (this.$refs[commentAreaRef][0].value.length === 0) {
        this.$refs[commentAreaRef][0].value = item.comment
        this.timelineCommentResize()
        this.changeDisableTimeLineComment(item)
        return
      }
      const formData = new FormData()
      formData.append('comment', this.$refs[commentAreaRef][0].value)
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/request_comment/${item.id}/update`, formData)
        .then(res => {
          this.readDataFromAPI()
          this.$store.commit('setFlashSuccess', 'コメントを編集しました。')
        })
        .catch(err => {
          this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
        })
      this.changeDisableTimeLineComment(item)
    },
    editTimelineCommentCancel (item) {
      const commentAreaRef = 'timeLine-comment-area' + item.id
      this.$refs[commentAreaRef][0].value = item.comment
      this.timelineCommentResize()
      this.changeDisableTimeLineComment(item)
    }
  },
  computed: {
    unit: function () {
      if (this.request.plan_mst) {
        return (this.request.plan_mst.contract_model === 'point') ? 'Pt' : '回'
      }
      return ''
    },
    totalPoint: function () {
      let total = 0
      for (const item of this.request.request_points) {
        total += parseInt(item.fix_count)
      }
      return total
    },
    selectAccount: function () {
      var rtn = []
      for (const elm of this.requester_accounts) {
        if (elm.id !== this.request.submit_login.id) { // 「依頼者」を除外
          const items = {}
          items.text = elm.name
          items.value = elm.id
          rtn.push(items)
        }
      }
      return rtn
    },
    selectRequestAssignedLogins: function () {
      const list = []
      list.push({
        text: 'なし',
        value: ''
      })
      if (this.request.involved_logins) {
        this.request.involved_logins.end_user.forEach(item => {
          list.push({
            text: item.name,
            value: item.id
          })
        })
        this.request.involved_logins.admin_company.forEach(item => {
          list.push({
            text: item.name,
            value: item.id
          })
        })
      }
      return list
    },
    defaultDateFormat: function () {
      return this.$store.getters.defaultDateFormat
    },
    hasNextStatus: function () {
      // console.log('next_status_text' in this.request.current_status)
      if ('current_status' in this.request) {
        if ('next_status_text' in this.request.current_status) {
          if (this.request.current_status.next_status_text !== null) {
            return true
          }
        }
      }
      return false
    },
    requestFinalPoint () {
      return (!this.request.point_rate) ? this.totalPoint : this.totalPoint * this.request.point_rate
    },
    AllStatus: function () {
      return this.$store.getters.getAllStatus
    },
    AllStatusWithoutCancel: function () {
      return this.AllStatus?.filter(row => row.is_cancel !== true)
    },
    ActiveChangeStatus: function () {
      const currentStatus = this.request?.current_status
      if (currentStatus?.is_needs_user_approval !== true) {
        return []
      }
      return this.AllStatus?.filter(row => row.step === (currentStatus?.step + 1))
    },
    contentAreaStyle () {
      return {
        width: '100%',
        overflow: 'hidden',
        resize: 'none',
        display: 'none'
      }
    },
    showAssignedLoginInTimeline () {
      return (this.request.assigned_login)
    }
  }
}
</script>

<style lang="scss" scoped>

.v-toolbar__title {
  white-space: inherit;
}

.v-stepper--vertical .v-stepper__step{
  padding: 12px 5px;
}
.v-stepper--vertical .v-stepper__content{
  margin: 0px 0px 0px 16px !important;
  padding: 6px;
}

.v-sheet.v-stepper{
  padding-bottom: 0;
}

.v-card__text{
  white-space: pre-line;
  font-size: 1rem;
}

.edit-member{
  display: none;
}

.v-expansion-panel--active{
  .edit-member{
    display: inline-block;
  }
}

::v-deep td.point{
  width: 70px;
}

.changed-status{
  margin: 16px 0 40px 0;
  position: relative;
  &:before{
    content: "";
    display: block;
    height: 1px;
    border-top: 1px dotted #000;
  }
  span{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.add{
  position: sticky;
  bottom: 0;
  padding: 20px 0 10px 0;
  z-index: 50;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,.8) 60%, rgba(255,255,255,0) 100%);
  width: 100%;
}

</style>
