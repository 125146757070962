<template>
  <div class="vue-reactions">
    <div
      :key="`selected-reaction-${reaction.id}`"
      v-for="reaction in selectedReactions"
    >
      <v-tooltip bottom color="#fff">
        <template v-slot:activator="{ on, attrs }">
          <button
            class="vue-reactions__btn"
            @click="addReaction(reaction.id)"
            :class="{ 'vue-reactions__btn--active': reaction.isActive }"
            type="button"
            v-bind="attrs"
            v-on="on"
          >
            <span v-html="reactions[reaction.id].emoji"></span>
            <span class="vue-reactions__count" v-if="reaction.count && reaction.count >= 1">{{ reaction.count }}</span>
          </button>
        </template>
        <span class="vue-reactions__tooltip">
          {{ reactions[reaction.id].label }}<br/>
          <span class="vue-reacions__user" v-for="user in reaction.user" :key="`selected-reaction-${user.name}`">
            <LoginAvatar :user="user" size="20" class="vue-reacions__user--icon" />
            <span>{{ user.name }}</span>
          </span>
        </span>
      </v-tooltip>
    </div>
    <div class="vue-add-reactions">
      <div class="vue-add-reactions-icon">
        <img src="@/assets/ico/reaction_icon.svg" width="22">
        <span class="pl-1" v-if="requestId && !selectedReactions.length">リアクション</span>
      </div>
      <div v-if="reactions" class="vue-reactions__dropdown">
        <div class="vue-reactions__dropdown-grid">
          <div :key="index" v-for="(reaction,index) in reactions">
            <v-tooltip content-class="vue-reactions__reaction-tooltip" bottom color="#000">
              <template v-slot:activator="{ on, attrs }">
                <button
                  @click="addReaction(index)"
                  type="button"
                  class="vue-reactions__reaction"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span v-html="reaction.emoji"></span>
                </button>
              </template>
              <span class="vue-reactions__reaction-tooltip-label">{{ reaction.label }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import LoginAvatar from '@/components/LoginAvatar.vue'

export default {
  components: { LoginAvatar },
  props: {
    requestId: {
      default: null
    },
    commentId: {
      default: null
    }
  },
  data () {
    return {
      selectedReactions: [],
      reactions: {
        nice: {
          label: 'いいね',
          emoji: '👍'
        },
        checking: {
          label: '確認中です',
          emoji: '👀'
        },
        thanks: {
          label: '感謝',
          emoji: '🙏'
        },
        roger: {
          label: '承りました',
          emoji: '🙋‍♂️'
        }
      }
    }
  },
  created: function () {
    this.getReactions()
  },
  methods: {
    getReactions () {
      var endpoint = this.requestId ? `request/${this.requestId}/content_reaction` : `request_comment/${this.commentId}/reaction`
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/${endpoint}`)
        .then(res => {
          this.selectedReactions = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    addReaction (reactionId) {
      const formData = new FormData()
      formData.append('emoji_name', reactionId)

      var endpoint = this.requestId ? `request/${this.requestId}/content_reaction` : `request_comment/${this.commentId}/reaction`
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/${endpoint}`, formData)
        .then(res => {
          this.selectedReactions = res.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-reactions {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 30px;
}

.vue-reactions__btn {
  border: rgba(0, 0, 0, 0.12) 1px solid;
  font-size: 16px;
  height: 24px;
  min-width: 40px;
  border-radius: 12px;
  span {
    &.vue-reactions__count {
      font-size: 12px;
      color: #3c6673;
      font-weight: bold;
    }
  }
}

.vue-reactions__btn--active {
  border: 1px solid #65aac1;
  background-color: #eff6f8;
}

.vue-reactions__dropdown {
  display: none;
  position: absolute;
  padding-top: 8px;
  z-index: 100;
}

.vue-reactions__dropdown-grid {
  box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.6);
  background-color: #fff;
  border-radius: 16px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
}

.vue-reactions__reaction {
  font-size: 14px;
  transition: 0.2s ease;
  background: transparent;
  width: 28px;
  height: 36px;
}

.vue-reactions__reaction:hover {
  font-size: 22px;
}

.vue-add-reactions:hover .vue-reactions__dropdown {
  display: block;
}

.vue-add-reactions {
  cursor: context-menu;
}

.vue-add-reactions-icon {
  display: flex;
  align-items: center;
}

.vue-reactions__icon:hover {
  // font-size: 26px;
}

.vue-reactions__tooltip {
  color: #000;
}

.vue-reacions__user{
  display: flex;
  margin-top: 5px;
  font-size: 14px;
}

.vue-reacions__user--icon {
  width: 20px;
  height: auto;
  border-radius: 20px;
  margin-right: 10px;
}

.v-tooltip__content {
  box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.6);
  padding: 10px 20px;
}

.vue-reactions__reaction-tooltip {
  padding: 5px 10px;
}

.vue-reactions__reaction-tooltip-label {
  font-size: 16px;
  font-weight: 600;
}
</style>
